import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import routes from './routes';
import * as Auth from './helpers/Auth';

import store from './redux/store';
import { Provider } from 'react-redux';

const App = () => {
  const routing = useRoutes(routes(Auth.isAuthenticated()));

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{routing}</ThemeProvider>
    </Provider>
  );
};

export default App;
