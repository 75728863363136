import React from 'react';
import Modal from 'react-modal';
import { makeStyles } from '@mui/styles';
import theme from '../../theme';
import { Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  modalFilterContainer: {
    padding: '0px !important',
  },
  modalFilterHeader: {
    maxWidth: 'calc(100% - 8px)',
    marginLeft: '8px !important',
    marginTop: '8px !important',
    backgroundColor: '#EBEEF2',
    color: '#6C757D',
    padding: '16px 24px 16px 24px !important',
    borderRadius: '4px 4px 0px 0px !important',
  },
  modalFilterHeaderText: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  modalFilterContent: {
    padding: '10px 24px 24px 24px !important',
  },
  modalFilterItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0px !important',
  },
  modalFilterBtn: {
    boxShadow: theme.shadows[5],
  },

  modalContent: {
    marginTop: '24px !important',
    paddingBottom: '24px !important',
  },

  pageTextContainer: {
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '5px',
    padding: '10px 20px !important',
    marginBottom: '8px',
    cursor: 'pointer',
  },

  pageTextContainerSelected: {
    backgroundColor: 'rgba(108, 117, 125, 0.3)',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '5px',
    padding: '10px 20px !important',
    marginBottom: '8px',
    cursor: 'pointer',
  },

  pageText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },

  closeBtnModalContainer: {
    position: 'relative',
  },
  closeBtnModal: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px !important',
    position: 'absolute',
    top: '-8px',
    right: '-16px',
    backgroundColor: 'white',
    color: '#6C757D',
    borderRadius: '100%',
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  profileTools: {
    fontWeight: 'bold',
  },
}));

const ModalPages = ({
  showModalSelectPage,
  closeModalSelectPage,
  displayTitles,
  /*   pageSelectedIndex,
  setPageSeleted, */
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const customStyles = {
    content: {
      width: '500px',
      minHeight: '260px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      boxShadow: theme.shadows[5],
      overflow: 'visible',
    },
    overlay: {
      zIndex: 2000,
      backgroundColor: 'rgba(196, 196, 196, 0.5)',
    },
  };

  const gotoPage = (link) => {
    navigate(link, { replace: true });
    /* setPageSeleted(index); */
    closeModalSelectPage();
  };

  return (
    <Modal
      isOpen={showModalSelectPage}
      onRequestClose={closeModalSelectPage}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="ModalFilter"
    >
      <Container className={classes.modalFilterContainer}>
        <div className={classes.closeBtnModalContainer}>
          <div className={classes.closeBtnModal} onClick={closeModalSelectPage}>
            <CloseIcon />
          </div>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.modalFilterHeader}>
            <Typography className={classes.modalFilterHeaderText}>
              Selecciona el dashboard que deseas ver:
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.modalContent}>
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                {displayTitles.map((page, index) => (
                  <Grid
                    item
                    xs={12}
                    key={`${index}-page-selector`}
                    onClick={() => gotoPage(page.link)}
                    className={classes.pageTextContainer}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.pageText}>
                        {page.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ModalPages;
