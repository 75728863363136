import React from 'react';
import Modal from 'react-modal';
import { makeStyles } from '@mui/styles';
import theme from '../../theme';
import { Button, Container, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import * as Auth from '../../helpers/Auth';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  modalFilterContainer: {
    padding: '0px !important',
  },
  modalFilterHeader: {
    maxWidth: 'calc(100% - 8px)',
    marginLeft: '8px !important',
    marginTop: '8px !important',
    backgroundColor: '#EBEEF2',
    color: '#6C757D',
    padding: '16px 24px 16px 24px !important',
    borderRadius: '4px 4px 0px 0px !important',
  },
  modalFilterHeaderText: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  modalFilterContent: {
    padding: '10px 24px 24px 24px !important',
  },
  modalFilterItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0px !important',
  },
  modalFilterBtn: {
    boxShadow: theme.shadows[5],
  },

  modalContent: {
    marginTop: '24px !important',
  },

  modalContentProfile: {
    margin: '24px 0px !important',
  },

  membershipContainer: {
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '5px',
    padding: '10px 20px !important',
    marginBottom: '8px',
    cursor: 'pointer',
  },

  membershipContainerSelected: {
    backgroundColor: 'rgba(108, 117, 125, 0.3)',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '5px',
    padding: '10px 20px !important',
    marginBottom: '8px',
    cursor: 'pointer',
  },

  organizationText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  buildingText: {
    fontSize: '14px',
  },
  roleContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    color: '#6c757d',
  },
  roleText: {
    fontSize: '14px',
  },
  buttonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  membershipsList: {
    maxHeight: '420px',
    overflowY: 'auto',
    paddingRight: '16px',
  },
  closeBtnModalContainer: {
    position: 'relative',
  },
  closeBtnModal: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px !important',
    position: 'absolute',
    top: '-8px',
    right: '-16px',
    backgroundColor: 'white',
    color: '#6C757D',
    borderRadius: '100%',
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  profileTools: {
    fontWeight: 'bold',
  },
}));

const ModalOrganizations = ({
  showModalOrganizations,
  closeModalOrganizations,
  data,
  selectedMembership,
  selectAction,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const customStyles = {
    content: {
      width: '500px',
      minHeight: '260px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      boxShadow: theme.shadows[5],
      overflow: 'visible',
    },
    overlay: {
      zIndex: 2000,
      backgroundColor: 'rgba(196, 196, 196, 0.5)',
    },
  };

  const logoutAction = () => {
    Auth.logout();
    navigate('/login');
  };

  return (
    <Modal
      isOpen={showModalOrganizations}
      onRequestClose={closeModalOrganizations}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="ModalFilter"
    >
      <Container className={classes.modalFilterContainer}>
        <div className={classes.closeBtnModalContainer}>
          <div
            className={classes.closeBtnModal}
            onClick={closeModalOrganizations}
          >
            <CloseIcon />
          </div>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.modalFilterHeader}>
            <Typography className={classes.modalFilterHeaderText}>
              Instalaciones
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.modalContent}>
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.membershipsList}>
                  {!isEmpty(data) &&
                    data.map((membership) => (
                      <Grid
                        item
                        xs={12}
                        key={membership.memId}
                        onClick={() => selectAction(membership)}
                        className={
                          selectedMembership.memId === membership.memId
                            ? classes.membershipContainerSelected
                            : classes.membershipContainer
                        }
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={9}>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.organizationText}
                                >
                                  {membership.buildingName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12} className={classes.modalFilterHeader}>
            <Typography className={classes.modalFilterHeaderText}>
              Perfil
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.modalContentProfile}>
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.profileTools}>
                  <Button
                    variant="text"
                    color="primary"
                    style={{ fontWeight: 'bold', color: 'black' }}
                    onClick={() => logoutAction()}
                  >
                    Cerrar sesión
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ModalOrganizations;
