import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import BusinessIcon from '@mui/icons-material/Business';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageContainer: {},
  iconContainer: {
    textAlign: 'center',
  },
  textContainer: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
  },
}));

const SelectBuilding = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={1} className={classes.messageContainer}>
          <Grid item xs={12} className={classes.iconContainer}>
            <BusinessIcon style={{ fontSize: '200px', color: '#215897' }} />
          </Grid>
          <Grid item xs={12} className={classes.textContainer}>
            Debe seleccionar una instalación
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectBuilding;
