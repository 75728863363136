import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as LoginServices from '../services/login';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  containerContent: {
    width: '100% !important',
    height: 'calc(100vh - 112px) !important',
    backgroundColor: '#EBEEF2 !important',
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginFormContainer: {
    width: '400px',
    minHeight: '400px',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
}));

const Login = ({ setMembershipsData }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loginMsg, setLoginMsg] = useState();

  return (
    <div className={classes.containerContent}>
      <Helmet>
        <title>Login | Alliot TV</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Debe ser un correo electrónico válido')
                .max(255)
                .required('Se requiere un correo electrónico'),
              password: Yup.string()
                .max(255)
                .required('Se requiere una contraseña'),
            })}
            onSubmit={(data) => {
              LoginServices.login(data.email, data.password)
                .then((response) => {
                  const cookies = new Cookies();

                  if (response.status === 201) {
                    cookies.set('token', response.data.public_token, {
                      path: '/',
                    });
                    cookies.set(
                      'name',
                      `${response.data.user.first_name} ${response.data.user.last_name}`,
                      { path: '/' }
                    );
                    cookies.set('email', response.data.user.email, {
                      path: '/',
                    });
                    cookies.set('pagecounter', 0, { path: '/' });

                    const membershipsData = response.data.user.memberships.map(
                      (membership) => ({
                        memId: membership.id,
                        memName: membership.role,
                        buildingId: membership.building.id,
                        buildingName: membership.building.name,
                        organizationId: membership.building.organization.id,
                        organizationName: membership.building.organization.name,
                      })
                    );

                    const membershipsDataFiltered = membershipsData.filter(
                      (m) =>
                        m.memName === 'organization_admin' ||
                        m.memName === 'warehouse_admin'
                    );

                    setMembershipsData(membershipsDataFiltered);

                    localStorage.setItem(
                      'memberships',
                      JSON.stringify(membershipsDataFiltered)
                    );

                    setLoginMsg('Bienvenido');
                    navigate('/app/dashboard', {
                      replace: true,
                    });
                  } else {
                    setLoginMsg('Usuario y/o contraseña inválidos.');
                  }
                })
                .catch(() => {
                  setLoginMsg('Usuario y/o contraseña inválidos.');
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <Card>
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        color="textPrimary"
                        variant="inherit"
                        align="center"
                      >
                        <img
                          alt="isotipo Alliot"
                          src="/static/images/isotipo.png"
                          height="64"
                        />
                      </Typography>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                        align="center"
                      >
                        Iniciar Sesión
                      </Typography>
                      {loginMsg !== '' && (
                        <Typography
                          color="textPrimary"
                          variant="body2"
                          align="center"
                          style={{
                            fontSize: '16px',
                            color: 'red',
                            marginTop: '20px',
                          }}
                        >
                          {loginMsg}
                        </Typography>
                      )}
                    </Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      autoComplete="off"
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Correo Electrónico"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      required
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      autoComplete="off"
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      required
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        // disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Iniciar Sesión
                      </Button>
                    </Box>
                  </form>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setMembershipsData(newMembership) {
    dispatch({
      type: 'SET_MEMBERSHIP_DATA',
      membership: newMembership,
    });
  },
});

export default connect(null, mapDispatchToProps)(Login);
