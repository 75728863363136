import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import MiniDataBox from '../DataBoxes/components/MiniDataBox';

import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from '@mui/icons-material/Group';

const useStyles = makeStyles(() => ({
  BoxItem: {
    padding: '20px !important',
  },
}));

const LoansInProgress = ({ items_borrowed, users_with_borrowed_assets }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.BoxItem}>
        <MiniDataBox
          boxTitle={'Usuarios con préstamos en curso'}
          boxContent={users_with_borrowed_assets}
          icon={
            <GroupIcon
              style={{ fontSize: '40px', color: 'rgba(127,127,127,0.5)' }}
            />
          }
        />
      </Grid>
      <Grid item xs={6} className={classes.BoxItem}>
        <MiniDataBox
          boxTitle={'Artículos con préstamo en curso'}
          boxContent={items_borrowed}
          icon={
            <GridViewIcon
              style={{ fontSize: '40px', color: 'rgba(127,127,127,0.5)' }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default LoansInProgress;
