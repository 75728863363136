import axios from 'axios';
import { BACKEND_CLOUD } from '../consts/backend';
import * as Auth from '../helpers/Auth';

export const getDataDashTwo = (buildindId, membershipId) =>
  new Promise((resolve, reject) => {
    const token = Auth.getToken();
    const TIMEZONE_OFFSET = new Date().getTimezoneOffset();

    axios
      .get(
        `${BACKEND_CLOUD}/api/buildings/${buildindId}/reports/building_availability`,
        {
          headers: {
            'Access-Control-Allow-Origin': true,
            Authorization: `User ${token}`,
            'x-alliotcloud-membershipid': membershipId,
            'X-Client-TimezoneOffset': TIMEZONE_OFFSET,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
