import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
  dataTableContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    /* minHeight: '662px', */
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  tableTitleDesc: {
    fontSize: '14px',
  },
  tableSubTitle: {
    fontSize: '14px',
  },
  tableData: {
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '20px',
    minHeight: '420px',
  },
  headerTable: {
    borderBottom: '1px solid #D9D9D9',
  },

  headerTableItem: {
    padding: '10px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  headerTableItemTotal: {
    borderLeft: '1px dashed #D9D9D9',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyTable: {},
  bodyRowTable: {
    fontSize: '14px',
    borderBottom: '1px solid #D9D9D9',
  },
  bodyTableItemName: {
    padding: '15px 10px 10px 20px !important',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  bodyTableItem: {
    padding: '15px 10px 10px 10px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyTableItemTotal: {
    borderLeft: '1px dashed #D9D9D9',
    padding: '15px 10px 10px 10px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DataTable = ({
  title = '',
  titleDesc = '',
  subTitle = '',
  headers = [],
  data = [],
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.dataTableContainer}>
      <Grid item xs={12}>
        <span className={classes.tableTitle}>{title}</span>{' '}
        <span className={classes.tableTitleDesc}>{titleDesc}</span>
      </Grid>
      <Grid item xs={12} className={classes.tableSubTitle}>
        {subTitle}
      </Grid>
      <Grid item xs={12} className={classes.tableData}>
        <Grid container spacing={1} className={classes.headerTable}>
          {headers.map((htext, index) => (
            <Grid
              item
              xs={htext.size}
              key={`${index}-datatable-header-item`}
              className={classes.headerTableItem}
            >
              {htext.value}
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1} className={classes.bodyTable}>
          <Grid item xs={12}>
            {data.map((d, index) => (
              <Grid
                container
                key={`${index}-datatable-body-item`}
                spacing={1}
                className={classes.bodyRowTable}
              >
                <Grid item xs={4} className={classes.bodyTableItemName}>
                  {d.username}
                </Grid>
                <Grid item xs={3} className={classes.bodyTableItem}>
                  {d.not_repairable}
                </Grid>
                <Grid item xs={3} className={classes.bodyTableItem}>
                  {d.repairable}
                </Grid>
                <Grid item xs={2} className={classes.bodyTableItemTotal}>
                  {d.total}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataTable;
