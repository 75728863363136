import React from 'react';
import { makeStyles } from '@mui/styles';
import DataTable from '../DataBoxes/components/DataTable';

const useStyles = makeStyles(() => ({
  BoxItem: {
    padding: '20px !important',
    color: '#7F7F7F',
  },
}));

const TopUsersWDelays = ({ top_10_users_with_delay }) => {
  const classes = useStyles();

  const dataTable = top_10_users_with_delay.slice(0, 10);

  const headerLabels = [
    { value: 'Nombre de usuario', type: 'text', size: 4 },
    {
      value: (
        <div>
          <span style={{ color: 'red' }}>No reparables</span> en morosidad
        </div>
      ),
      type: 'html',
      size: 3,
    },
    {
      value: (
        <div>
          <span style={{ color: 'red' }}>Reparables</span> en morosidad
        </div>
      ),
      type: 'html',
      size: 3,
    },
    { value: '# Total activos morosos', type: 'text', size: 2 },
  ];

  return (
    <div className={classes.BoxItem}>
      <DataTable
        title={'Listado de usuarios con morosidad'}
        titleDesc={''}
        subTitle={
          'Se considera morosidad cuando herramienta no se ha devuelto antes de 30 días'
        }
        headers={headerLabels}
        data={dataTable}
      />
    </div>
  );
};

export default TopUsersWDelays;
