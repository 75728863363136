import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Auth from '../../helpers/Auth';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from './Logo';
import ModalOrganizations from '../Login/ModalOrganizations';
import { setSelectedMembership } from '../../helpers/Actions';
import SelectBuildingBtn from './SelectBuildingBtn';
import ModalPages from '../Login/ModalPages';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ModalConfig from '../Login/ModalConfig';
import { DisplayTitles } from '../../helpers/displayTitles';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  logoContainer: {
    width: '220px',
    height: '64px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    /* border: '1px solid red', */
    backgroundColor: '#215897',
    marginLeft: '-24px',
  },
  toolBar: {
    height: 64,
    backgroundColor: '#215897',
  },
  boldText: {
    fontWeight: 'bold',
  },
  dashTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '22px',
    cursor: 'pointer',
  },
  tmp: {
    color: 'white',
    fontWeight: 'bold',
  },
  configDisplay: {
    color: 'white',
  },
}));

const PageNavbar = ({
  title,
  state,
  setPageState,
  setMembership,
  selMembership,
  /*   pageSelectedIndex,
  setPageSeleted, */
  ...rest
}) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [showPagesModal, setShowPagesModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [membershipsData, setMembershipsData] = useState({});

  const openBuildingModal = () => {
    setShowBuildingModal(true);
  };

  const closeBuildingModal = () => {
    setShowBuildingModal(false);
  };

  const openPagesModal = () => {
    setShowPagesModal(true);
  };

  const closePagesModal = () => {
    setShowPagesModal(false);
  };

  const openConfigModal = () => {
    setShowConfigModal(true);
  };

  const closeConfigModal = () => {
    setShowConfigModal(false);
  };

  const selectMembership = (membership) => {
    setMembership(membership);
    setSelectedMembership(membership);
    closeBuildingModal();
  };

  const getMemberships = () => {
    const membershipsByUser = Auth.getMemberships();
    if (membershipsByUser.length === 1) {
      setMembership(membershipsByUser[0]);
    }
    setMembershipsData(membershipsByUser);
  };

  let contador = cookies.get('pagecounter')
    ? parseInt(cookies.get('pagecounter'))
    : 0;

  const rotateDashboards = () => {
    contador++;
    if (contador === DisplayTitles.length) {
      contador = 0;
    }
    cookies.set('pagecounter', contador, {
      path: '/',
    });

    navigate(DisplayTitles[contador].link, { replace: true });
  };

  useEffect(() => {
    getMemberships();
    if (
      cookies.get('isDisplayChange') === 'true' &&
      parseInt(cookies.get('tvNum') ? cookies.get('tvNum') : 0) === 1 &&
      parseInt(
        cookies.get('changeDashTime') ? cookies.get('changeDashTime') : 0
      ) > 0
    ) {
      setInterval(() => {
        rotateDashboards();
      }, parseInt(cookies.get('changeDashTime')) * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar elevation={0} {...rest}>
        <Toolbar className={classes.toolBar}>
          <RouterLink to="/">
            <div className={classes.logoContainer}>
              <Logo />
            </div>
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <div className={classes.dashTitle} onClick={() => openPagesModal()}>
            {title}
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <div className={classes.tmp} onClick={() => openBuildingModal()}>
            <SelectBuildingBtn membershipData={selMembership} />
          </div>
          <div>
            <IconButton
              aria-label="config"
              className={classes.configDisplay}
              onClick={() => openConfigModal()}
            >
              <DisplaySettingsIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <ModalOrganizations
        showModalOrganizations={showBuildingModal}
        closeModalOrganizations={closeBuildingModal}
        data={membershipsData}
        selectedMembership={selMembership}
        selectAction={selectMembership}
      />
      <ModalPages
        showModalSelectPage={showPagesModal}
        closeModalSelectPage={closePagesModal}
        displayTitles={DisplayTitles}
      />
      <ModalConfig
        showModalSelectPage={showConfigModal}
        closeModalSelectPage={closeConfigModal}
        displayTitles={DisplayTitles}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  title: state.dashTitleRed.title,
  state: state.dashTitleRed.state,
  //pageSelectedIndex: state.pageSelectedRed.pageSelectedIndex,
  selMembership: state.membershipSelectedRed.membership,
});

const mapDispatchToProps = (dispatch) => ({
  setMembership(newMembership) {
    dispatch({
      type: 'SET_MEMBERSHIP_DATA',
      membership: newMembership,
    });
  },
  setPageState(newState) {
    dispatch({
      type: 'SET_PAGE_FLAG',
      state: newState,
    });
  },
  /*   setPageSeleted(newPageIndex) {
    dispatch({
      type: 'SET_PAGE_SELECTED',
      pageSelectedIndex: newPageIndex,
    });
  }, */
});

export default connect(mapStateToProps, mapDispatchToProps)(PageNavbar);
