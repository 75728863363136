import { configureStore, combineReducers } from '@reduxjs/toolkit';
import dashTitleReducer from './reducers/dashTitleReducer';
import inventoryReducer from './reducers/inventoryReducer';
import membershipSelectedReducer from './reducers/membershipSelectedReducer';
import pageSelectedReducer from './reducers/pageSelectedReducer';

const rootReducer = combineReducers({
  membershipSelectedRed: membershipSelectedReducer,
  dashTitleRed: dashTitleReducer,
  inventoryRed: inventoryReducer,
  pageSelectedRed: pageSelectedReducer,
});

export default configureStore({ reducer: rootReducer });
