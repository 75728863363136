import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import theme from '../../../theme';
import FlagIcon from '@mui/icons-material/Flag';
import { getTotalPages } from '../../../helpers/functions';

const useStyles = makeStyles(() => ({
  dataTableContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
  },
  tableTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  tableTitleDesc: {
    fontSize: '12px',
  },
  tableSubTitle: {
    fontSize: '12px',
  },
  tableData: {
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '20px',
    height: '620px',
  },
  headerTable: {
    borderBottom: '1px solid #D9D9D9',
  },

  headerTableItem: {
    padding: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  headerTableItemTotal: {
    borderLeft: '1px dashed #D9D9D9',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bodyTable: {
    /* border: '1px solid blue', */
    height: '550px',
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '100%',
    /* border: '1px solid red', */
  },
  bodyRowTable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    borderBottom: '1px solid #D9D9D9',
    height: '62px',
    /* padding: '8px 0px', */
  },
  flagRed: {
    color: 'red',
    marginBottom: '-10px',
    fontSize: '24px',
  },
  bodyTableItemName: {
    textAlign: 'left',
    padding: '10px 10px 5px 40px !important',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  bodyTableItemToolName: {
    textAlign: 'left',
    padding: '10px 10px 5px 40px !important',
  },
  bodyTableItem: {
    textAlign: 'center',
    padding: '10px 10px 5px 10px !important',
  },
  bodyTableItemTotal: {
    borderLeft: '1px dashed #D9D9D9',
    textAlign: 'center',
    padding: '15px 10px 10px 10px !important',
  },
  tablePaginator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px !important',
    position: 'relative',
    height: '40px',
    marginTop: '0px',
    /* borderTop: '1px solid #D9D9D9', */
  },
  pagesNumContainer: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    bottom: '0px',
    paddingRight: '10px',
  },
  pageNum: {
    padding: '10px 10px 10px 10px !important',
  },
  pageNumSelected: {
    padding: '10px 10px 10px 10px !important',
    color: 'blue',
    fontWeight: 'bold',
  },
}));

const DataTableDetail = ({
  title = '',
  titleDesc = '',
  subTitle = '',
  headers = [],
  data,
  pagesNumArray,
  rowsPerPage = 10,
}) => {
  const classes = useStyles();
  const [pageSelected, setPageSelected] = useState(0);

  const timePageChange = 5000;

  let contador = 0;

  const pageCounter = (total) => {
    if (total > 0) {
      contador++;
      if (contador === total) {
        contador = 0;
      }
      setPageSelected(contador);
    }
  };

  useEffect(() => {
    setInterval(() => {
      pageCounter(getTotalPages(data, 10));
    }, timePageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesNumArray]);

  return (
    <Grid container spacing={1} className={classes.dataTableContainer}>
      <Grid item xs={12}>
        <span className={classes.tableTitle}>{title}</span>{' '}
        <span className={classes.tableTitleDesc}>{titleDesc}</span>
      </Grid>
      <Grid item xs={12} className={classes.tableSubTitle}>
        {subTitle}
      </Grid>
      <Grid item xs={12} className={classes.tableData}>
        <Grid container spacing={1} className={classes.headerTable}>
          {headers.map((htext, index) => (
            <Grid
              item
              xs={htext.size}
              key={`${index}-datatable-header-item`}
              className={classes.headerTableItem}
            >
              {htext.type === 'text' ? htext.value : htext.value}
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1} className={classes.bodyTable}>
          <Grid item xs={12} className={classes.rowsContainer}>
            {data
              .slice(
                pageSelected * rowsPerPage,
                pageSelected * rowsPerPage + rowsPerPage
              )
              .map((d, index) => (
                <Grid
                  container
                  key={`${index}-datatable-body-item`}
                  spacing={1}
                  className={classes.bodyRowTable}
                >
                  <Grid item xs={3} className={classes.bodyTableItemName}>
                    {d.username}
                  </Grid>
                  <Grid item xs={4} className={classes.bodyTableItemToolName}>
                    {d.asset_name}
                  </Grid>
                  <Grid item xs={1} className={classes.bodyTableItem}>
                    {d.mark}
                  </Grid>
                  <Grid item xs={2} className={classes.bodyTableItem}>
                    {d.borrowed_days >= 30 ? (
                      <div>
                        <span>{d.borrowed_days}</span>
                        <FlagIcon className={classes.flagRed} />
                      </div>
                    ) : (
                      <span>{d.borrowed_days}</span>
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.bodyTableItem}>
                    {d.area}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.tablePaginator}>
          <div className={classes.pagesNumContainer}>
            {pagesNumArray.map((p, index) => (
              <div
                key={`${index}-table-page`}
                className={
                  index === pageSelected
                    ? classes.pageNumSelected
                    : classes.pageNum
                }
              >
                {p}
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataTableDetail;
