import React from 'react';
import { Navigate } from 'react-router-dom';
import PageLayout from './components/web-layout/PageLayout';
import MainLayout from './components/web-layout/MainLayout';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import DashOne from './pages/DashOne';
import DashTwo from './pages/DashTwo';
import DashThree from './pages/DashThree';

const routes = (isAuthenticated) => [
  {
    path: 'app',
    element: isAuthenticated ? <PageLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'dashone', element: <DashOne /> },
      { path: 'dashtwo', element: <DashTwo /> },
      { path: 'dashthree', element: <DashThree /> },
    ],
  },
  {
    path: '/',
    element: !isAuthenticated ? <MainLayout /> : <Navigate to="/app/dashone" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashone" /> },
      /* { path: '/two', element: <Navigate to="/app/dashtwo" /> }, */
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
