import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import MiniTreeDataBox from '../DataBoxes/components/MiniTreeDataBox';
import theme from '../../theme';
import GridViewIcon from '@mui/icons-material/GridView';
import BuildIcon from '@mui/icons-material/Build';
import HandymanIcon from '@mui/icons-material/Handyman';
import HardwareIcon from '@mui/icons-material/Hardware';

const useStyles = makeStyles(() => ({
  BoxItem: {
    padding: '20px !important',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    color: '#7F7F7F',
  },
  BoxTitle: {
    fontSize: '20px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
}));

const InventoryState = ({ items_with_available_stock, assets_available }) => {
  const classes = useStyles();

  const dataInventoryState = [
    {
      label: 'Total',
      value: items_with_available_stock ? items_with_available_stock.total : 0,
      type: 'total',
      icon: null,
    },
    {
      label: 'Consumibles',
      value: items_with_available_stock
        ? items_with_available_stock.consumables
        : 0,
      type: 'data',
      icon: <GridViewIcon />,
    },
    {
      label: 'No consumibles',
      value: items_with_available_stock
        ? items_with_available_stock.not_consumables
        : 0,
      type: 'data',
      icon: <BuildIcon />,
    },
  ];

  const dataAssets = [
    {
      label: 'Total',
      value: assets_available ? assets_available.total : 0,
      type: 'total',
      icon: null,
    },
    {
      label: 'No reparables',
      value: assets_available ? assets_available.not_repairable : 0,
      type: 'data',
      icon: <HandymanIcon />,
    },
    {
      label: 'Reparables',
      value: assets_available ? assets_available.repairable : 0,
      type: 'data',
      icon: <HardwareIcon />,
    },
  ];

  return (
    <Grid container spacing={1} className={classes.BoxItem}>
      <Grid item xs={12} className={classes.BoxTitle}>
        Estado macro de inventario
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <MiniTreeDataBox
              title={'Artículos con stock disponible'}
              data={dataInventoryState}
            />
          </Grid>
          <Grid item xs={6}>
            <MiniTreeDataBox title={'Activos disponibles'} data={dataAssets} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InventoryState;
