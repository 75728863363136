import React from 'react';
import { makeStyles } from '@mui/styles';
import DataTable from '../DataBoxes/components/DataTable';

const useStyles = makeStyles(() => ({
  BoxItem: {
    padding: '20px !important',
    color: '#7F7F7F',
  },
}));

const RankingUsersByLoan = ({ users_with_borrowed_ranking }) => {
  const classes = useStyles();

  const dataTable = users_with_borrowed_ranking.slice(0, 10);

  const headerLabels = [
    { value: 'Nombre de usuario', type: 'text', size: 4 },
    {
      value: (
        <div>
          <span style={{ color: 'red' }}>No reparables</span> prestados
        </div>
      ),
      type: 'html',
      size: 3,
    },
    {
      value: (
        <div>
          <span style={{ color: 'red' }}>Reparables</span> prestados
        </div>
      ),
      type: 'html',
      size: 3,
    },
    { value: '# Total activos prestados', type: 'text', size: 2 },
  ];

  return (
    <div className={classes.BoxItem}>
      <DataTable
        title={'Ranking usuarios por #préstamos'}
        subTitle={
          'Ranking de usuarios según cantidad de prestamos últimos 30 días móviles.'
        }
        headers={headerLabels}
        data={dataTable}
      />
    </div>
  );
};

export default RankingUsersByLoan;
