import React from 'react';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';

const MiniDataBox = ({ boxTitle, boxContent, heightBox = '118px', icon }) => {
  const useStyles = makeStyles(() => ({
    MiniBoxData: {
      backgroundColor: 'white',
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      padding: '20px',
      height: heightBox,
      position: 'relative',
    },
    MiniBoxDataTitle: {},
    MiniBoxDataContent: {
      lineHeight: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '80px',
    },
    MiniBoxIconContainer: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.MiniBoxData}>
      <div className={classes.MiniBoxDataTitle}>{boxTitle}</div>
      <div className={classes.MiniBoxDataContent}>{boxContent}</div>
      <div className={classes.MiniBoxIconContainer}>{icon}</div>
    </div>
  );
};

export default MiniDataBox;
