import axios from 'axios';
import { BACKEND_CLOUD } from '../consts/backend';

// eslint-disable-next-line import/prefer-default-export
export const login = (email, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${BACKEND_CLOUD}/api/session`,
        { user: { email, password } },
        {
          headers: {
            'Access-Control-Allow-Origin': true,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
