//import * as Auth from '../../helpers/Auth';
import { getSelectedMembership } from '../../helpers/Actions';

const initialMembershipSeletedState = {
  membership: getSelectedMembership() !== null ? getSelectedMembership() : [], //Auth.getMemberships(),
};

const membershipSelectedReducer = (
  state = initialMembershipSeletedState,
  action
) => {
  if (action.type === 'SET_MEMBERSHIP_DATA') {
    return {
      ...state,
      membership: action.membership,
    };
  }
  return state;
};

export default membershipSelectedReducer;
