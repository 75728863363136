import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import theme from '../../theme';
import PersonIcon from '@mui/icons-material/Person';
import ConstructionIcon from '@mui/icons-material/Construction';

const useStyles = makeStyles(() => ({
  BoxItem: {
    padding: '20px 20px 20px 20px !important',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    color: '#7F7F7F',
  },
  BoxTitle: {
    fontSize: '20px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  itemIconContainer: {
    color: '#215897',
    textAlign: 'center',
  },
  itemIcon: {
    fontSize: '35px',
  },
  itemValue: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  itemLabel: {
    textAlign: 'center',
    fontSize: '14px',
  },
  test: {
    border: '1px solid red',
  },
  boxContainer: {
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    padding: '20px 10px 20px 10px !important',
  },
}));

const AssetsNotAvailable = ({ assets_not_available }) => {
  const classes = useStyles();
  const dataAssetsNotAvailables = [
    {
      label: 'En préstamo',
      value: assets_not_available ? assets_not_available.borrowed_assets : 0,
      icon: <PersonIcon className={classes.itemIcon} />,
    },
    {
      label: 'En proceso MRO',
      value: assets_not_available ? assets_not_available.in_mro : 0,
      icon: <ConstructionIcon className={classes.itemIcon} />,
    },
  ];

  return (
    <Grid container spacing={1} className={classes.BoxItem}>
      <Grid item xs={12} className={classes.BoxTitle}>
        Activos no disponibles
      </Grid>
      <Grid item xs={12} className={classes.boxContainer}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Grid
              container
              spacing={1}
              style={{ borderRight: '1px solid #D9D9D9' }}
            >
              <Grid item xs={12} className={classes.itemIconContainer}>
                {dataAssetsNotAvailables[0].icon}
              </Grid>
              <Grid item xs={12} className={classes.itemValue}>
                {dataAssetsNotAvailables[0].value}
              </Grid>
              <Grid item xs={12} className={classes.itemLabel}>
                {dataAssetsNotAvailables[0].label}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.itemIconContainer}>
                {dataAssetsNotAvailables[1].icon}
              </Grid>
              <Grid item xs={12} className={classes.itemValue}>
                {dataAssetsNotAvailables[1].value}
              </Grid>
              <Grid item xs={12} className={classes.itemLabel}>
                {dataAssetsNotAvailables[1].label}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssetsNotAvailable;
