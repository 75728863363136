const initialTitleDashState = {
  title: '',
  state: true,
};

const dashTitleReducer = (state = initialTitleDashState, action) => {
  if (action.type === 'SET_DASHBOARD_TITLE') {
    return {
      ...state,
      title: action.title,
    };
  }
  if (action.type === 'SET_PAGE_FLAG') {
    return {
      ...state,
      state: action.state,
    };
  }
  return state;
};

export default dashTitleReducer;
