import { createTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import shadows from './shadows';
import typography from './typography';
import palette from './palette';

const theme = createTheme(
  {
    palette,
    shadows,
    typography,
  },
  esES
);

export default theme;
