import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import theme from '../../theme';
import VerticalChart from '../DataBoxes/components/VerticalChart';

const useStyles = makeStyles(() => ({
  BoxItem: {
    marginTop: '2px',
    padding: '20px 20px 42px 20px !important',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
  },
  BoxTitle: {
    marginBottom: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#7F7F7F',
  },
}));

const BuildingActivity = ({
  clients_served,
  daily_deliveries_consumables,
  daily_assets_borrowed,
  daily_asset_returns,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.BoxItem}>
      <Grid item xs={12} className={classes.BoxTitle}>
        Actividad dentro de la instalación
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <VerticalChart
              title={'Usuarios atendidos'}
              value={clients_served ? clients_served[7].total : 0}
              subtitle={'Usuarios atendidos de hoy'}
              datachart={{
                data: clients_served ? clients_served : [],
                color: '#7CB4FB',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <VerticalChart
              title={'Entregas diarias de consumibles'}
              value={
                daily_deliveries_consumables
                  ? daily_deliveries_consumables.results[7].total
                  : 0
              }
              subtitle={'Retiros de hoy'}
              datachart={{
                data: daily_deliveries_consumables
                  ? daily_deliveries_consumables.results
                  : [],
                color: '#B1CF95',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <VerticalChart
              title={'Préstamos diarios de activos'}
              value={
                daily_assets_borrowed
                  ? daily_assets_borrowed.results[7].total
                  : 0
              }
              subtitle={'Prestamos de hoy'}
              datachart={{
                data: daily_assets_borrowed
                  ? daily_assets_borrowed.results
                  : [],
                color: '#F9DA78',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <VerticalChart
              title={'Devoluciones diarias de activos'}
              value={
                daily_asset_returns ? daily_asset_returns.results[7].total : 0
              }
              subtitle={'Devoluciones de hoy'}
              datachart={{
                data: daily_asset_returns ? daily_asset_returns.results : [],
                color: '#EAB38A',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuildingActivity;
