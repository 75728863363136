import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  btnBuilding: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));

const SelectBuildingBtn = ({ membershipData }) => {
  const classes = useStyles();
  return (
    <Button variant="text" className={classes.btnBuilding}>
      {!membershipData.buildingName
        ? 'Seleccionar instalación'
        : membershipData.buildingName}
    </Button>
  );
};

export default SelectBuildingBtn;
