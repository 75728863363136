const initialPageSeletedState = {
  pageSelectedIndex: 0, //Auth.getMemberships(),
};

const pageSelectedReducer = (state = initialPageSeletedState, action) => {
  if (action.type === 'SET_PAGE_SELECTED') {
    return {
      ...state,
      pageSelectedIndex: action.pageSelectedIndex,
    };
  }
  return state;
};

export default pageSelectedReducer;
