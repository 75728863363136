import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import MiniDataBox from '../DataBoxes/components/MiniDataBox';
import HorizontalChart from '../DataBoxes/components/HorizontalChart';
import DataTableDetail from '../DataBoxes/components/DataTableDetail';
import theme from '../../theme';
import BuildIcon from '@mui/icons-material/Build';
import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from '@mui/icons-material/Group';
import * as DashThreeServices from '../../services/dashthree';
import { calculatePages } from '../../helpers/functions';

const useStyles = makeStyles(() => ({
  leftCol: {
    marginTop: '12px',
    color: '#7F7F7F',
  },
  dashSection: {
    margin: '0px',
    borderBottom: '3px solid rgba(255, 255, 255, 0.65)',
  },
  boxDashContainer: {
    margin: '0px',
  },
  boxDash: {
    padding: '20px !important',
  },
  BoxItem: {
    padding: '20px !important',
    color: '#7F7F7F',
  },
  HorizontalChartContainer: {
    padding: '20px!important',
    color: '#7F7F7F',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    maxHeight: '180px',
    marginTop: '10px',
    marginLeft: '8px',
  },
}));

const DashThreeBody = ({ selMembership, setTitle }) => {
  const classes = useStyles();

  const [dashThreeData, setDashThreeData] = useState(null);
  const [pagesNumArray, setPagesNumArray] = useState([0]);

  const getDashThreeData = () => {
    setTitle('Cargando datos...');
    DashThreeServices.getDataDashThree(
      selMembership.buildingId,
      selMembership.memId
    )
      .then((dashthreedata) => {
        setDashThreeData(dashthreedata.data);
        setPagesNumArray(
          calculatePages(
            dashthreedata.data.users_with_borrowed_assets_detail,
            10
          )
        );
        setTitle('Detalle de herramientas en préstamo');
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  useEffect(() => {
    getDashThreeData();
    const interval = setInterval(() => {
      getDashThreeData();
    }, 3600000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]);

  const headerLabels = [
    { value: 'Nombre de usuario', type: 'text', size: 3 },
    { value: 'Nombre de herramienta', type: 'text', size: 4 },
    { value: 'Rótulo', type: 'text', size: 1 },
    { value: 'Días en préstamo', type: 'text', size: 2 },
    { value: 'Área de usuario', type: 'text', size: 2 },
  ];

  const dataChart = [
    {
      label: 'Inferior a 15 días',
      value: dashThreeData ? dashThreeData.borrow_intervals.rank1 : 0,
      color: '#B1CF95',
    },
    {
      label: 'De 16 a 30 días',
      value: dashThreeData ? dashThreeData.borrow_intervals.rank2 : 0,
      color: '#F9DA78',
    },
    {
      label: 'De 31 a 90 días',
      value: dashThreeData ? dashThreeData.borrow_intervals.rank3 : 0,
      color: '#EAB38A',
    },
    {
      label: 'De sobre 90 días',
      value: dashThreeData ? dashThreeData.borrow_intervals.rank4 : 0,
      color: '#D98085',
    },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={3} className={classes.leftCol}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MiniDataBox
              boxTitle={'Usuarios con préstamos en curso'}
              boxContent={
                dashThreeData ? dashThreeData.users_with_borrowed_assets : 0
              }
              heightBox={'136px'}
              icon={
                <GroupIcon
                  style={{ fontSize: '40px', color: 'rgba(127,127,127,0.5)' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MiniDataBox
              boxTitle={'Artículos con préstamo en curso'}
              boxContent={dashThreeData ? dashThreeData.items_borrowed : 0}
              heightBox={'136px'}
              icon={
                <GridViewIcon
                  style={{ fontSize: '40px', color: 'rgba(127,127,127,0.5)' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MiniDataBox
              boxTitle={'Activos en préstamo'}
              boxContent={dashThreeData ? dashThreeData.assets_borrowed : 0}
              heightBox={'136px'}
              icon={
                <BuildIcon
                  style={{ fontSize: '40px', color: 'rgba(127,127,127,0.5)' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.HorizontalChartContainer}>
                <HorizontalChart data={dataChart} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <div className={classes.BoxItem}>
          <DataTableDetail
            title={'Detalle de préstamos'}
            subTitle={
              'Se considera morosidad cuando herramienta no se ha devuelto antes de 30 días'
            }
            headers={headerLabels}
            data={
              dashThreeData
                ? dashThreeData.users_with_borrowed_assets_detail
                : []
            }
            pagesNumArray={pagesNumArray}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  selMembership: state.membershipSelectedRed.membership,
});

const mapDispatchToProps = (dispatch) => ({
  setTitle(newTitle) {
    dispatch({
      type: 'SET_DASHBOARD_TITLE',
      title: newTitle,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashThreeBody);
