import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

const useStyles = makeStyles(() => ({
  boxContainer: {
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    padding: '10px 10px 30px 10px',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxTitle: {
    marginBottom: '10px',
  },
  totalValue: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  totalLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderLeft: '1px solid #D9D9D9',
  },
  itemValue: {
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  itemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#215897',
    /* border: '1px solid red', */
  },
  itemLabel: {
    fontSize: '14px',
    textAlign: 'center',
  },
}));

const MiniTreeDataBox = ({ title, data }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.boxContainer}>
      <Grid item xs={12} className={classes.boxTitle}>
        {title}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.itemsContainer}>
            {data.map((d, index) =>
              d.type === 'total' ? (
                <Grid container spacing={1} key={`${index}-tree-data-box-item`}>
                  <Grid item xs={12} className={classes.totalValue}>
                    {d.value}
                  </Grid>
                  <Grid item xs={12} className={classes.totalLabel}>
                    {d.label}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  key={`${index}-tree-data-box-item`}
                  className={classes.itemContainer}
                >
                  <Grid item xs={3} className={classes.itemIcon}>
                    {d.icon}
                  </Grid>
                  <Grid item xs={9} className={classes.itemValue}>
                    {d.value}
                  </Grid>
                  <Grid item xs={12} className={classes.itemLabel}>
                    {d.label}
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MiniTreeDataBox;
