import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import MiniDataBox from '../DataBoxes/components/MiniDataBox';
import HorizontalChart from '../DataBoxes/components/HorizontalChart';
import BuildIcon from '@mui/icons-material/Build';

const useStyles = makeStyles(() => ({
  BoxItem: {
    padding: '20px !important',
  },
}));

const AssetsInLoan = ({ assets_borrowed, borrow_intervals }) => {
  const dataChart = [
    {
      label: 'Inferior a 15 días',
      value: borrow_intervals ? borrow_intervals.rank1 : 0,
      color: '#B1CF95',
    },
    {
      label: 'De 16 a 30 días',
      value: borrow_intervals ? borrow_intervals.rank2 : 0,
      color: '#F9DA78',
    },
    {
      label: 'De 31 a 90 días',
      value: borrow_intervals ? borrow_intervals.rank3 : 0,
      color: '#EAB38A',
    },
    {
      label: 'De sobre 90 días',
      value: borrow_intervals ? borrow_intervals.rank4 : 0,
      color: '#D98085',
    },
  ];
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} className={classes.BoxItem}>
        <MiniDataBox
          boxTitle={'Activos en préstamo'}
          boxContent={assets_borrowed}
          icon={
            <BuildIcon
              style={{ fontSize: '40px', color: 'rgba(127,127,127,0.5)' }}
            />
          }
        />
      </Grid>
      <Grid item xs={8} className={classes.BoxItem}>
        <HorizontalChart data={dataChart} />
      </Grid>
    </Grid>
  );
};

export default AssetsInLoan;
