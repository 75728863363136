export const setSelectedMembership = (membership) => {
  localStorage.setItem('selectedMemberships', JSON.stringify(membership));
};

export const removeSelectedMembership = () => {
  localStorage.removeItem('selectedMemberships');
};

export const getSelectedMembership = () => {
  return JSON.parse(localStorage.getItem('selectedMemberships'));
};

export const isMembershipSelected = () => {
  const selMembership = JSON.parse(localStorage.getItem('selectedMemberships'));
  if (selMembership) {
    return true;
  }
  return false;
};
