import React from 'react';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';
import { Grid } from '@mui/material';

const HorizontalChart = ({ data }) => {
  /* const orderData = [...data].sort((a, b) => {
    return Number.parseInt(b.value) - Number.parseInt(a.value);
  }); */

  const maxValue = Math.max(...data.map((x) => parseInt(x.value))); // orderData[0].value;

  const useStyles = makeStyles(() => ({
    chartContainer: {
      marginTop: '10px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    chartItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    labelItem: {
      fontSize: '14px',
      width: '100%',
      /* border: '1px solid red', */
    },
    barContainer: {
      width: '100%',
      height: '100%',
      padding: '0px !important',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    valueItem: {
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '0 10px',
      /*     width: '100%', */
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.chartContainer}>
      {data.map((d, index) => (
        <Grid
          container
          key={`${index}-horizontal-chart-item`}
          spacing={1}
          className={classes.chartItemContainer}
        >
          <Grid item xs={4} className={classes.labelItem}>
            {d.label}
          </Grid>
          <Grid item xs={8} className={classes.barContainer}>
            <div
              style={{
                width: `${Math.round((d.value / maxValue) * 100)}% `,
                height: '100%',
                boxShadow: theme.shadows[5],
                backgroundColor: d.color,
                borderRadius: '3px',
              }}
            ></div>
            <div className={classes.valueItem}>{d.value}</div>
          </Grid>
          {/* <Grid item xs={1} className={classes.valueItem}>
            {d.value}
          </Grid> */}
        </Grid>
      ))}
    </div>
  );
};

export default HorizontalChart;
