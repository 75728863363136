import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';
import { getDayOfWeek } from '../../../helpers/functions';
const VerticalChart = ({ title, value, subtitle, datachart }) => {
  const maxValue = Math.max(...datachart.data.map((x) => parseInt(x.total)));
  const lastIndex = datachart.data.length - 1;
  const useStyles = makeStyles(() => ({
    verticalChartBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid #D9D9D9',
      borderRadius: '5px',
      paddingTop: '20px',
      color: '#7F7F7F',
    },
    itemTitle: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    valueContainer: {
      width: '200px',
      paddingRight: '10px',
      paddingBottom: '10px',
      marginBottom: '20px',
      border: '1px solid #D9D9D9',
      borderRadius: '5px',
      height: '110px',
    },
    itemValue: {
      fontSize: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
      /* border: '1px solid red', */
      color: '#7F7F7F',
    },
    itemSubTitle: {
      fontSize: '14px',
      textAlign: 'center',
      /* marginBottom: '20px', */
      fontWeight: 'bold',
      color: '#7F7F7F',
    },
    chartBarsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around !important',
    },
    chartBarGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    chartBarGroupLast: {
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '2px dashed red',
      paddingLeft: '2px',
    },
    valueBarChart: {
      fontSize: '11px',
      textAlign: 'center',
      marginBottom: '10px',
      fontWeight: 'bold',
      color: 'black',
    },
    barChartContainer: {
      height: '200px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    labelBarChart: {
      width: '50px',
      fontSize: '11px',
      textAlign: 'center',
      marginTop: '5px',
      color: 'black',
    },
  }));

  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.verticalChartBox}>
      <Grid item xs={12} className={classes.itemTitle}>
        {title}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} className={classes.valueContainer}>
          <Grid item xs={12} className={classes.itemValue}>
            {value}
          </Grid>
          <Grid item xs={12} className={classes.itemSubTitle}>
            {subtitle}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ width: '360px', marginBottom: '40px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.chartBarsContainer}>
            {datachart.data.map((dchart, index) => (
              <div
                className={
                  index === lastIndex
                    ? classes.chartBarGroupLast
                    : classes.chartBarGroup
                }
                key={`${index}-vertical-chart`}
              >
                <div className={classes.valueBarChart}>{dchart.total}</div>
                <div className={classes.barChartContainer}>
                  <div
                    style={{
                      backgroundColor: datachart.color,
                      width: '35px',
                      boxShadow: theme.shadows[5],
                      height: `${Math.round(
                        (dchart.total / maxValue) * 100
                      )}% `,
                    }}
                  ></div>
                </div>
                <div className={classes.labelBarChart}>
                  {index === lastIndex ? 'hoy' : getDayOfWeek(dchart.date)}
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerticalChart;
