export const DisplayTitles = [
  {
    index: 0,
    name: 'Resúmen de prestamos en curso y ranking de morosidad de usuarios',
    link: '/app/dashone',
  },
  {
    index: 1,
    name: 'Monitoreo de actividad en Instalación',
    link: '/app/dashtwo',
  },
  {
    index: 2,
    name: 'Detalle de herramientas en préstamo',
    link: '/app/dashthree',
  },
];
