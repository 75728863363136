export const getDayOfWeek = (dateStr) => {
  const dateSplit = dateStr.split('-');
  dateStr =
    dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0] + ' 00:00:00';
  const days = [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ];
  const dayNumber = new Date(dateStr).getDay();
  return days[dayNumber];
};

export const getTotalPages = (data, rowsPerPage) => {
  const basenum = Math.round(data.length / rowsPerPage);
  const resto = data.length % rowsPerPage;
  let totalPages = basenum;
  if (resto > 0) {
    totalPages = totalPages + 1;
  }
  return totalPages - 1;
};

export const calculatePages = (data, rowsPerPage) => {
  const basenum = Math.round(data.length / rowsPerPage);
  const resto = data.length % rowsPerPage;
  let totalPages = basenum;
  if (resto > 0) {
    totalPages = totalPages + 1;
  }
  let pagesNumArray = [];
  for (let i = 0; i < totalPages; i++) {
    pagesNumArray.push(i + 1);
  }
  return pagesNumArray;
};
