import React from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';
import PageNavbar from './PageNavbar';

const PageLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const PageLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
}));

const PageLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const PageLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const PageLayout = () => {
  return (
    <PageLayoutRoot>
      <PageNavbar />
      <PageLayoutWrapper>
        <PageLayoutContainer>
          <PageLayoutContent>
            <Outlet />
          </PageLayoutContent>
        </PageLayoutContainer>
      </PageLayoutWrapper>
    </PageLayoutRoot>
  );
};

export default PageLayout;
