import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { removeSelectedMembership } from './Actions';

export const getToken = () => {
  const cookie = new Cookies();
  return cookie.get('token');
};

export const getMembershipId = () => {
  const cookie = new Cookies();
  return cookie.get('mem_id');
};

export const isAuthenticated = () => {
  const token = getToken();
  if (token) {
    return true;
  }
  return false;
};

export const getUserData = () => {
  const cookie = new Cookies();
  return {
    name: cookie.get('name'),
    email: cookie.get('email'),
    avatar: cookie.get('organization_avatar'),
  };
};

export const getUserId = () => {
  const cookie = new Cookies();
  return jwt_decode(cookie.get('token')).uid;
};

export const getMemberships = () => {
  //const cookie = new Cookies();
  //return cookie.get('memberships');
  return JSON.parse(localStorage.getItem('memberships'));
};

export const logout = () => {
  const cookie = new Cookies();
  cookie.remove('token', { path: '/' });
  cookie.remove('name', { path: '/' });
  cookie.remove('email', { path: '/' });
  cookie.remove('organization_avatar', { path: '/' });
  cookie.remove('memberships', { path: '/' });
  cookie.remove('changeDashTime', { path: '/' });
  cookie.remove('tvNum', { path: '/' });
  cookie.remove('isDisplayChange', { path: '/' });
  cookie.remove('dashsToRotate', { path: '/' });
  cookie.remove('pagecounter', { path: '/' });
  localStorage.removeItem('memberships');
  removeSelectedMembership();
};
