const initialInventoryState = {
  inventory: null,
};

const inventoryReducer = (state = initialInventoryState, action) => {
  if (action.type === 'SET_INVENTORY_DATA') {
    return {
      ...state,
      inventory: action.inventory,
    };
  }
  return state;
};

export default inventoryReducer;
